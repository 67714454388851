





















































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class MainPageBody extends Vue {
  model = 0;
  currentIndex = 0;
  beatModel = 0;
  Rythm = require("../../../../public/rythm.js");
  rythm = new this.Rythm();
  play = true;
  searchResult = 0;
  imagesIndex = 0;
  hideViolence = false;
  inputMP3: any | null = null;
  imagesInterval: number | null = null;
  playOwn = false;
  me: any = {
    name: "Andrej",
    instagram: "andrej_developer",
    about: "Developer/Producer",
  };
  songs: any[] = [
    {
      index: 0,
      mp3: "https://secure-everglades-49603.herokuapp.com/https://onedrive.live.com/download?cid=25E892A4FA706225&resid=25E892A4FA706225%211144255&authkey=AMiWSBTakc_-_Rw",
      text: "Everlasting (for sale on home page)",
    },
    {
      index: 1,
      mp3: "https://secure-everglades-49603.herokuapp.com/https://onedrive.live.com/download?cid=25E892A4FA706225&resid=25E892A4FA706225%211144256&authkey=AC4UDCJZRuTB33g",
      text: "Sleepy Village (for sale on home page)",
    },
    
  ];
  belowImgs: any[] = [
    {
      // img: "https://i.ytimg.com/vi/YZl1tc7qUvE/maxresdefault.jpg",
      img: "logo.webp",
      // affiliate: [
      //   {
      //     img: "https://cdn.shopify.com/s/files/1/0064/2216/7634/products/H3429b60671d249b19b4490da1e6ffbddg_1024x1024@2x.jpg?v=1643834146",
      //     link: "https://www.vvsjewelry.com/collections/iced-out-chains/products/vvs-jewelry-14k-gold-silver-chain-free-bracelet-bundle-today-only?ref=o-U6-0bMxEys",
      //     text: "VVS JEWELRY CUBAN CHAIN + FREE CUBAN BRACELET BUNDLE: $59",
      //   },
      // ],
    },
    {
      // img: "https://imgix.ranker.com/list_img_v2/8386/2908386/original/2908386?w=817&h=427&fm=jpg&q=50&fit=crop",
      img: "logo.webp",
      // affiliate: [
      //   {
      //     img: "https://cdn.shopify.com/s/files/1/0064/2216/7634/products/product-image-1427133016_1024x1024@2x.jpg?v=1612510988",
      //     link: "https://www.vvsjewelry.com/collections/watches/products/diamondz-bust-down-watch?ref=o-U6-0bMxEys",
      //     text: "DIAMONDZ BUST DOWN WATCH: $59",
      //     // img: "https://www.airgunon.com/image/cache/catalog/Products/2020082510160442-1100x1100w.jpg",
      //     // link: "https://www.airgunon.com/umarex-syrix?tracking=MEwLKJ5vFvWwDQA6l7UJXiVneTZrcJdZ5UGEorCmST3Dpearg263Gp6w51mrrMtt",
      //     // text: "UMAREX SYRIX AIR RIFLE: $100.79",
      //   },
      // ],
    },
    {
      // img: "https://images.squarespace-cdn.com/content/v1/596001c2579fb355caec7aac/1511909893759-29Y9KPQEK7704MS0N2Q7/image-asset.png?format=1000w",
      img: "logo.webp",
      // affiliate: [
      //   {
      //     img: "https://cdn.shopify.com/s/files/1/0064/2216/7634/products/product-image-1326436563_1024x1024@2x.jpg?v=1600455935",
      //     link: "https://www.vvsjewelry.com/collections/hip-hop-clothing/products/nwa-snapback-hat?ref=o-U6-0bMxEys",
      //     text: "NWA SNAPBACK HAT: $27",
      //   },
      // ],
    },
    {
      // img: "https://static0.cbrimages.com/wordpress/wp-content/uploads/2020/08/Dragon-Ball-Z-Ultra-Instinct-Goku-e1597785363690.jpg",
      img: "logo.webp",
      // affiliate: [
      //   {
      //     img: "https://cdn.shopify.com/s/files/1/0064/2216/7634/products/product-image-1436767909_1024x1024@2x.jpg?v=1645159059",
      //     link: "https://www.vvsjewelry.com/collections/speakers/products/graffiti-bluetooth-speaker?ref=o-U6-0bMxEys",
      //     text: "GRAFFITI BLUETOOTH SPEAKER: $110",
      //   },
      // ],
    },
  ];
  // Put this in vuex state and it will remain playing even for this page (maybe)

  tryy(mp3: string) {
    if (this.inputMP3) {
      this.rythm.start();
      this.play = false;
      this.imagesInterval = setInterval(() => {
        this.imagesIndex++;
      }, 1500);
      return;
    }
    this.rythm.setMusic(mp3);
    this.rythm.pulseRatio = 0;
    this.rythm.start();
    this.play = false;
    this.imagesInterval = setInterval(() => {
      this.imagesIndex++;
    }, 1500);
  }
  onSearch(): void {
    if (this.searchResult || this.searchResult === 0) {
      this.currentIndex = this.searchResult;
      this.rythm.stop();
      this.play = true;
      if (this.songs[this.searchResult].hideViolence) {
        this.hideViolence = true;
      }
    }
  }
  onChange(): void {
    clearInterval(this.imagesInterval!);
    this.rythm.stop();
    this.searchResult = this.currentIndex;
    this.play = true;
  }
  handleFiles(event: any) {
    console.log("Here");
    this.rythm.stop();
    var files = event.target.files;
    this.inputMP3 = URL.createObjectURL(files[0]);
    console.log(this.inputMP3);
    this.rythm.setMusic(this.inputMP3);
    this.rythm.start();
    this.play = false;
    // document.getElementById("src")?.setAttribute("src",  URL.createObjectURL(files[0]))
    // document.getElementById("audio")!.load();
  }
  onRemoveMP3(): void {
    this.inputMP3 = null;
    this.rythm.stop();
    this.play = true;
    (document.getElementById("upload") as HTMLInputElement)!.value = "";
  }
  destroyed(): void {
    this.rythm.stop();
  }
  sendToAffiliate(link: string): void {
    window.open(link, "_blank");
  }
  mounted(): void {
    scroll(0, 0);
  }
  allowPlayOwn(): void {
    this.playOwn = true;
    console.log(this.$refs);
    const music = this.$refs.musicInput as HTMLElement;
    console.log(music);
    music.addEventListener("change", this.handleFiles, false);
  }

  get showCondensedVersion(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }
}
