















import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Income2 extends Vue {}
