import Vue from "vue";
import ExternalFacingView from "../components/main/ExternalFacingView.vue"
import VueRouter, { RouteConfig } from "vue-router";
import Body from "../components/main/MainPage/Body.vue"
import Rap from "../components/blog/interactiveBeats/main.vue"
import Money from "../components/blog/making-money.vue"
import MakingBeats from "../components/blog/making-beats.vue"
import Blog from "../components/blog/main.vue"

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    name: "Not Found",
    redirect: () => "/not-found",
  },
  {
    path: "/",
    component: ExternalFacingView,
    children: [
      // {
      //   path: "",
      //   redirect: () => ({ name: "Login" }),
      // },
      {
        path: "",
        name: "Body",
        component: Body,
      },
      {
        path: "blog",
        name: "Blog",
        component: Blog,
      },
      {
        path: "interactive-beats",
        name: "Rap",
        component: Rap,
      },
      {
        path: "making-money",
        name: "Money",
        component: Money,
      },
      {
        path: "making-beats",
        name: "MakingBeats",
        component: MakingBeats,
      },
      // {
      //   path: "reset",
      //   name: "Reset Password",
      //   component: ResetPassword,
      // },
      // {
      //   path: "forgot-password",
      //   name: "Forgot Password",
      //   component: ForgotPassword,
      // },
    ],
  },
]

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  // const script = document.createElement("script");
  // script.src = "https://udbaa.com/bnr.php?section=General&pub=616219&format=300x250&ga=g"
  // script.type = "text/javascript";
  // document.head.appendChild(script);

  // const script2 = document.createElement("script");
  // script.src = "https://udbaa.com/slider.php?section=General&pub=616219&ga=g&side=random"
  // script.type = "text/javascript";
  // document.head.appendChild(script2);

  // const script3 = document.createElement("script");
  // script.src = "https://vdbaa.com/pup.php?section=General&pt=2&pub=616219&ga=g"
  // script.type = "text/javascript";
  // document.head.appendChild(script3);
  next()
  // if (localStorage.getItem("reloaded")) {
  //   // The page was just reloaded. Clear the value from local storage
  //   // so that it will reload the next time this page is visited.
  //   localStorage.removeItem("reloaded");
  // } else {
  //   // Set a flag so that we know not to reload the page twice.
  //   localStorage.setItem("reloaded", "1");
  //   location.reload();
  //   //test
  // }
  
});

export default router;