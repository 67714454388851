import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c(VRow,{staticClass:"mt-2",attrs:{"justify":"center"}},[_c('img',{staticClass:"rounded-lg mt-10",attrs:{"src":"logo.png","alt":"","height":"100"}})]),_c(VRow,{staticClass:"mt-10 px-16",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"lg":"7","cols":"12"}},[_c(VTextField,{attrs:{"label":"Search","single-line":"","hide-details":"","dense":"","dark":"","prepend-icon":"mdi-magnify"},on:{"keyup":_vm.updateAfterSearch},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c(VCol,{attrs:{"align":"start","cols":"auto"}},[_c(VDialog,{staticClass:"bg-green",attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"bg-green",attrs:{"dark":""}},'v-btn',attrs,false),on),[_vm._v(" Filters ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"set-opactiy bg-gold font-weight-bold overflow-auto",attrs:{"dark":"","height":"350"}},[_c('div',{staticClass:"pa-4"},_vm._l((_vm.filters),function(filter,i){return _c(VCheckbox,{key:i,attrs:{"label":filter,"value":filter},on:{"click":_vm.updateAfterSearch},model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}})}),1)])],1)],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c('h1',{staticClass:"set-title-font"},[_vm._v("...More Coming Daily")])]),_c(VRow,{staticClass:"mt-10 px-15",attrs:{"justify":"center"}},_vm._l((_vm.historyList),function(blog,i){return _c(VCol,{key:i,attrs:{"md":"12","sm":"12","lg":"12"}},[_c(VCard,{staticClass:"d-flex flex-column justify-space-between bg-gold border-card",attrs:{"max-height":"390","min-height":"390"}},[_c('div',{staticClass:"blog card-height",class:{ 'on-hover': _vm.hover }},[_c(VImg,{attrs:{"src":"logo.webp","height":"200px"}}),_c(VCardSubtitle,[_c('h1',{staticClass:"set-title-font"},[_vm._v(_vm._s(blog.about))])])],1),_c(VCardActions,{staticClass:"d-flex justify-center"},[_c(VBtn,{staticClass:"black--text",attrs:{"text":""},on:{"click":function($event){return _vm.takeTo(blog.route)}}},[_vm._v("Read")])],1),(_vm.hover)?_c('span',{staticClass:"px-10"},[_c('div',{staticClass:"mb-4"},[_c('h3',[_vm._v("Glimpse")])]),_c('div',[_vm._v(_vm._s(blog.summary))])]):_vm._e()],1)],1)}),1),_c(VRow,{attrs:{"justify":"center"}},[_c(VPagination,{staticClass:"pagination mb-15",attrs:{"length":_vm.pages,"color":"gold"},on:{"input":_vm.updatePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }