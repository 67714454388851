




















































































import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Income extends Vue {
  blogs = [
    {
      img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
      id: "1",
      filters: ["Learning"],
      route: "Money",
      about: "Making Money",
    },
    {
      img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
      id: "1",
      filters: ["Learning"],
      route: "MakingBeats",
      about: "Making Beats",
    },
  ];
  pageSize = 6;
  listCount = 0;
  searchText = "";
  selectedFilters = [];
  filters = ["Introduction"];
  dialog = false;
  page = 1;
  historyList: any[] = [];
  get filteredBlogs(): any[] {
    let blogs = this.blogs.filter((b) => b.about.includes(this.searchText));
    let filteredBlogs: any[] = [];
    if (this.selectedFilters.length) {
      blogs.forEach((b) => {
        this.selectedFilters.forEach((f) => {
          if (b.filters.includes(f)) {
            filteredBlogs.push(b);
          }
        });
      });
      return filteredBlogs;
    }
    return blogs;
  }
  updateAfterSearch() {
    this.initPage();
    this.updatePage(this.page);
  }
  randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  created() {
    this.initPage();
    this.updatePage(this.page);
  }
  updatePage(pageIndex: number) {
    // m.title.toLowerCase().startsWith(this.searchText)
    let blogs = this.blogs.filter((b) => b.about.includes(this.searchText));
    let filteredBlogs: any[] = [];
    if (this.selectedFilters.length) {
      blogs.forEach((b) => {
        this.selectedFilters.forEach((f) => {
          if (b.filters.includes(f)) {
            filteredBlogs.push(b);
          }
        });
      });
    } else {
      filteredBlogs = blogs;
    }
    let start = (pageIndex - 1) * this.pageSize;
    let end = pageIndex * this.pageSize;
    this.historyList = filteredBlogs.slice(start, end);
    this.page = pageIndex;
  }
  initPage() {
    let blogs = this.blogs.filter((b) => b.about.includes(this.searchText));
    let filteredBlogs: any[] = [];
    if (this.selectedFilters.length) {
      blogs.forEach((b) => {
        this.selectedFilters.forEach((f) => {
          if (b.filters.includes(f)) {
            filteredBlogs.push(b);
          }
        });
      });
    } else {
      filteredBlogs = blogs;
    }
    this.listCount = filteredBlogs.length;
    if (this.listCount < this.pageSize) {
      this.historyList = filteredBlogs;
    } else {
      this.historyList = filteredBlogs.slice(0, this.pageSize);
    }
  }
  takeTo(route: string): void {
    let routeData = this.$router.resolve({ name: route });
    window.open(routeData.href, "_blank");
  }
  get pages() {
    if (this.pageSize == null || this.listCount == null) return 0;
    return Math.ceil(this.listCount / this.pageSize);
  }
}
