














































































































import { Component, Vue } from "vue-property-decorator";
import MainHeader from "./MainPage/Header.vue";
@Component({
  components: {
    MainHeader,
  },
})
export default class ExternalFacingView extends Vue {
  drawer = false;
  takeToPage(place: string): void {
    this.$router.push({ name: place });
  }
  takeTo(url: string) {
    window.open(url, "_blank")!.focus();
  }
  takeToURL(url: string) {
    window.open(url, "_blank")!.focus();
  }
  icons: any[] = [
    {
      icon: "mdi-instagram",
      url: "https://www.instagram.com/andrej_developer/",
    },
    { icon: "mdi-youtube", url: "https://www.youtube.com/channel/UCtTwOFujTVQg07_nXH7rqHg" },
  ];
}
