import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"overflow-hidden"},[_c(VAppBar,{attrs:{"absolute":"","color":"deep-purple accent-4","dark":""}},[_c(VAppBarNavIcon),_c(VToolbarTitle,[_vm._v("Collapsing Bar")]),_c(VSpacer),_c(VCheckbox,{attrs:{"color":"white","hide-details":""},model:{value:(_vm.collapseOnScroll),callback:function ($$v) {_vm.collapseOnScroll=$$v},expression:"collapseOnScroll"}})],1),_c(VSheet,{staticClass:"overflow-y-auto",attrs:{"id":"scrolling-techniques-6","max-height":"600"}},[_c(VContainer,{staticStyle:{"height":"1000px"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }